<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      hide-footer
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group
          label="Department"
          invalid-feedback="Department is required."
          ref="dep"
        >
          <v-select
            v-model="filter.dep"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Departments', id: 0 }, ...departments]"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            ref="dep"
            :disabled="filterloading"
            placeholder="Select department"
          />
        </b-form-group>

        <b-form-group
          label="Status"
          invalid-feedback="status is required."
          ref="status"
        >
          <v-select
            v-model="currentStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :reduce="(opt) => opt.value"
            label="text"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select status"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <!-- @click="Filter()" -->
          <b-button
            @click="LoadData('filter')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-cards"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Print ID Card"
    >
      <b-form>
        <b-form-group
          label="Department"
          invalid-feedback="Department is required."
          ref="card_dep"
        >
          <v-select
            ref="card_dep"
            v-model="cardObj.depList"
            multiple
            :closeOnSelect="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="departments"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            :disabled="filterloading"
            placeholder="Select department"
            @input="setDep()"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="printByDep()"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="printing"
          >
            <span class="align-middle">Print</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Import</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="pt-1">
              <b-button
                @click="$refs.importStaff.click()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="staffImporting"
                block
              >
                <b-spinner
                  v-if="staffImporting"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="importStaff"
                    hidden
                    ref="importStaff"
                    @change="ImportStaff()"
                  />
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">Staff</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility2"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Staff Left</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility2 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Leaving Date"
                invalid-feedback="Date is required"
                ref="left_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.leaving_date"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="saving"
                block
                @click="markLeft()"
              >
                <b-spinner
                  v-if="saving"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Mark Left</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility3"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Staff Readmit</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility3 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Leaving Date"
                invalid-feedback="Date is required"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.leaving_date"
                  class="form-control"
                  placeholder="Select date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Rejoining Date"
                invalid-feedback="Date is required"
                ref="rejoin"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.rejoining_date"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="saving"
                block
                @click="markReadmit()"
              >
                <b-spinner
                  v-if="saving"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Readmit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-card>
        <b-row class="mt-1">
          <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
            <b-button
              class=""
              @click="AddOpen()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Add Staff</span>
            </b-button>
          </b-col>

          <b-col
            class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
            xl="6"
            lg="3"
            md="4"
            sm="12"
            cols="12"
          >
            <b-form-group class="ml-lg-2 ml-md-0 ml-sm-0 ml-0">
              <b-input-group>
                <b-input-group-prepend is-text @click="loadSearch()">
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model.trim="searchQuery"
                  placeholder="Search..."
                  @keyup.enter="loadSearch()"
                >
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="text-right" xl="2" lg="3" md="4" sm="12" cols="12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-modal.modal-login
              block
            >
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="align-middle">Filter</span>
            </b-button>
          </b-col>
          <b-col
            xl="2"
            lg="3"
            md="12"
            sm="12"
            cols="12"
            class="pl-lg-0 pr-50 mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex justify-content-between align-items-start"
          >
            <b-form-group
              label=""
              style="flex: 1"
              class="ml-md-0 ml-sm-0 ml-0 mb-0 pr-50"
            >
              <v-select
                v-model="currentStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="text"
                @input="LoadData('dd')"
                :clearable="false"
                placeholder="Select status."
              />
            </b-form-group>
            <b-button
              v-if="!rights.disableImport"
              @click="OpenImport()"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="Import"
            >
              <feather-icon icon="DownloadIcon" class="" />
            </b-button>
            <b-button
              v-if="!rights.disableExport"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Export"
              class="btn-icon ml-50"
              :disabled="exporting"
              @click="exportData()"
            >
              <b-spinner v-if="exporting" small varaint="primary" />
              <feather-icon v-else icon="UploadIcon" class="" />
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-lg-0 mt-md-1 mt-sm-1">
          <b-col cols="12" md="6" sm="6" class="pr-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 justify-content-end"
              prev-class="prev-item"
              next-class="next-item"
              @input="LoadData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-col cols="12" md="6" sm="6" class="text-right pl-0 mt-1 mt-sm-0">
            <b-button
              @click="printIDs()"
              variant="outline-primary"
              class="btn-icon"
              :disabled="printing"
            >
              <b-spinner
                v-if="printing"
                variant="primary"
                label="loading"
                small
              ></b-spinner>

              <span v-if="!printing">
                <feather-icon icon="PrinterIcon" class="mr-50" /> Print ID Card
              </span>
              <span v-if="!printing && idList.length > 0"
                >({{ idList.length }})</span
              >
            </b-button>
            <b-button
              v-if="idList.length > 0"
              @click="unselect()"
              variant="outline-secondary"
              class="btn-icon ml-1"
              :disabled="printing"
            >
              <feather-icon icon="XIcon" class="mr-50" />
              <span>Unselect All</span>
            </b-button>
            <b-button
              @click="syncApp()"
              variant="outline-primary"
              class="btn-icon ml-1"
              :disabled="syncLoading"
              v-b-tooltip.hover.top
              title="Sync App Accounts"
            >
              <b-spinner
                v-if="syncLoading"
                variant="primary"
                label="loading"
                small
              ></b-spinner>
              <feather-icon v-else icon="SmartphoneIcon" class="" />
            </b-button>
          </b-col>
        </b-row>

        <b-table
          class="mt-1"
          :busy="dataLoading"
          :tbody-tr-class="rowClass"
          ref="selectableTable"
          :items="items"
          :fields="showSalary && showSalary.valueBool ? fields2 : fields"
          show-empty
          responsive
          hover
          small
          :selectable="rights.edit"
          select-mode="multi"
          @row-selected="staffSelect($event)"
        >
          <template #empty="scope">
            <h3 class="mt-1 mb-1" style="text-align: center">
              No records found
            </h3>
          </template>
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #cell(index)="data">
            <!-- {{ data.index + 1 }} -->
            <b-badge variant="light-primary">
              <!-- {{ filters.indexOf(data.item) + 1 }} -->
              {{ (currentPage - 1) * perPage + data.index + 1 }}
            </b-badge>
          </template>

          <template #cell(picture)="data">
            <!-- <img
                :src="data.item.picture"
                alt="pic"
                style="height: 70px; width: 70px; object-fit: cover"
              /> -->
            <b-img
              v-if="data.item.picture !== null && data.item.picture !== ''"
              rounded
              :src="data.item.picture"
              height="100px"
              width="100px"
              alt=""
            />
            <b-img
              v-else
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              height="100px"
              width="100px"
            />
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="data.item.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ data.item.name }}
                </b-badge>
              </b-link>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ data.item.father_name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary" class="mr-50">
                  ID: {{ data.item.id }}
                </b-badge>
                <b-badge variant="light-primary">
                  RFID: {{ data.item.rfID }}
                </b-badge>
              </small>
              <b-link
                v-if="data.item.status.toLowerCase() == 'left'"
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-primary">
                  {{
                    new Date(data.item.leaving_date).toLocaleDateString(
                      "en-UK",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      }
                    )
                  }}
                </b-badge>
              </b-link>
              <b-link
                v-if="data.item.status.toLowerCase() == 'present'"
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-primary">
                  {{
                    new Date(data.item.appointedOn).toLocaleDateString(
                      "en-UK",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      }
                    )
                  }}
                </b-badge>
              </b-link>
            </b-media>
          </template>

          <template #cell(campus)="data">
            <b-badge variant="light-primary">
              {{ getCampus(data.item.campusID) }}
            </b-badge>
          </template>
          <template #cell(contact_number)="data">
            <b-badge variant="light-primary">
              {{ data.item.contact_number }}
            </b-badge>
          </template>
          <template #cell(department)="data">
            <b-badge variant="light-primary">
              {{ getDepName(data.item.departmentID) }}
            </b-badge>
          </template>
          <template #cell(qualification)="data">
            <b-badge variant="light-primary">
              {{ data.item.qualification }}
            </b-badge>
          </template>
          <template #cell(experience)="data">
            <b-badge variant="light-primary">
              {{ data.item.experience }}
            </b-badge>
          </template>
          <template #cell(designation)="data">
            <b-badge variant="light-primary">
              {{ data.item.designation }}
            </b-badge>
          </template>
          <template #cell(totalSalary)="data">
            <b-badge variant="light-primary">
              {{ data.item.totalSalary }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge variant="light-primary">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="
                  $store.state.rights['manage-planner'] &&
                  data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Planner"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="openPlanner(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" class="" />
              </b-button>

              <b-button
                v-if="
                  rights.edit && data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Timetable"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="timeTable(data.item.id)"
              >
                <feather-icon icon="ClipboardIcon" class="" />
              </b-button>

              <b-button
                v-if="rights.edit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Edit"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                :to="
                  data.item.status.toLowerCase() == 'applicant'
                    ? {
                        name: 'staff-application',
                        params: {
                          id: data.item.id,
                        },
                      }
                    : {
                        name: 'manage-staff',
                        params: {
                          id: data.item.id,
                        },
                      }
                "
              >
                <!-- @click="Edit(data.item.id)" -->
                <feather-icon icon="EditIcon" class="" />
              </b-button>

              <b-button
                v-if="
                  ctObj &&
                  ctObj.valueBool &&
                  rights.edit &&
                  data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Teaching Classes"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="openClasses(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>

              <b-button
                v-if="
                  rights.edit && data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Left"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="openLeft(data.item)"
              >
                <!-- @click="changeStatus(data.item, 'left')" -->
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-else-if="
                  rights.edit && data.item.status.toLowerCase() == 'left'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Readmit"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="openReadmit(data.item)"
              >
                <!-- @click="changeStatus(data.item, 'present')" -->
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Delete"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Delete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Perf(data.item.id)"
              >
                <feather-icon icon="StarIcon" />
              </b-button> -->
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ items.length }} of
              {{ totalCount == 0 ? items.length : totalCount }} entries</span
            >
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    vSelect,
    BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    // BFormCheckbox,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    BMediaAside,
    BImg,
    BMedia,
    //BLink,
    // BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          disableImport: ch.disableImport,
          disableExport: ch.disableExport,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      let obj = this.filterData.find(
        (el) =>
          el.db == this.$store.state.userData.db &&
          el.cID == this.$store.state.userData.cId
      );
      // console.log(obj);
      if (obj) {
        this.filter.dep = obj.depID;
        this.totalCount = obj.total;
        this.currentPage = obj.page;
        this.currentStatus = obj.currentStatus;
        this.LoadData();
      } else this.LoadData();

      // this.LoadData();
      this.LoadDep();
      this.loadSettings();
      this.LoadSettingsAll();
      if (this.reportDomain == "myskoolhpgs") {
        this.fields.splice(2, 0, { label: "campus", key: "campus" });
        this.fields2.splice(2, 0, { label: "campus", key: "campus" });
      }
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.id == this.searchQuery
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      filterloading: false,
      filter: {
        dep: 0,
        status: "",
      },
      classes: [],
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      request: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 50,
      totalCount: 0,
      errors: {
        status: false,
      },
      statusOptions: [
        { text: "Working", value: "present" },
        { text: "Left", value: "left" },
        { text: "Applicant", value: "applicant" },
      ],
      FilterStOptions: [
        { text: "All", value: "" },
        { text: "Working", value: "present" },
        { text: "Left", value: "left" },
        { text: "Applicant", value: "applicant" },
      ],
      currentStatus: "present",
      filterData: this.$store.state.staffFilter,
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "S.No", key: "index" },
        // { label: "id", key: "id" },
        // { label: "picture", key: "picture" },
        { label: "name", key: "name" },
        // { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_number" },
        { label: "department", key: "department" },
        { label: "qualification", key: "qualification" },
        { label: "Designation", key: "designation" },
        // { label: "experience", key: "experience" },
        // { label: "Total Periods", key: "totalPeriods" },
        { label: "status", key: "status" },
        "actions",
      ],
      fields2: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        { label: "contact", key: "contact_number" },
        { label: "department", key: "department" },
        { label: "qualification", key: "qualification" },
        { label: "Designation", key: "designation" },
        { label: "salary", key: "totalSalary" },
        { label: "status", key: "status" },
        "actions",
      ],
      currentSections: [],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {},
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      staffImporting: false,
      staffData: [],
      idList: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      printing: false,
      cardObj: {
        depList: [],
      },
      visibility2: false,
      visibility3: false,
      saving: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      showSalary: null,
      syncLoading: false,
      ctObj: null,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async syncApp() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "This will syncronize mobile accounts.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Sync it!",
      });

      if (result.isConfirmed) {
        this.syncLoading = true;
        var status = this.get({
          url:
            this.$store.state.domain +
            "staff/SyncAccounts?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Accounts synced successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.syncLoading = false;
      }
    },

    getDepName(id) {
      let obj = this.departments.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    getCampus(id) {
      let obj = this.$store.state.branches.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },
    staffSelect(row) {
      this.idList = row.reduce((acc, el) => {
        acc.push(el.id);
        return acc;
      }, []);

      // console.log(this.idList);
    },
    unselect() {
      this.$refs.selectableTable.clearSelected();
      // this.idList = [];
    },
    printIDs() {
      if (this.idList.length > 0) {
        console.log(this.idList);
        this.printing = true;
        var url =
          `https://${this.reportDomain}.myskool.app/Certificate/StaffCard?dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&staffID=" +
          this.idList +
          "&depID=0";
        window.open(url, "_blank");
        this.printing = false;
      } else {
        this.cardObj = {
          depList: [],
        };
        this.$bvModal.show("modal-cards");
      }
    },
    printByDep() {
      if (this.CheckDepartment() == true) {
        console.log(this.cardObj);
        this.printing = true;
        var url =
          `https://${this.reportDomain}.myskool.app/Certificate/StaffCard?dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&staffID=0&depID=" +
          this.cardObj.depList;
        window.open(url, "_blank");
        this.printing = false;
        this.$bvModal.hide("modal-cards");
      }
    },
    CheckDepartment() {
      var elem = this.$refs["card_dep"];
      if (this.cardObj.depList.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setDep() {
      if (this.cardObj.depList.at(-1) == 0) {
        this.cardObj.depList = [0];
      } else {
        this.cardObj.depList = this.cardObj.depList.filter((el) => el != 0);
      }
      // console.log(this.cardObj.depList);
    },

    async exportData() {
      this.exporting = true;

      const expData = this.items.map((item) => ({
        id: item.id,
        name: item.name,
        father: item.father_name,
        spouse: item.spouse,
        cnic: item.cnic,
        contact: item.contact_number,
        qualification: item.qualification,
        designation: item.designation,
        status: item.status,
        appointment_date: item.appointedOn,
        salary: item.totalSalary,
        address: item.address_current,
      }));

      const worksheet = XLSX.utils.json_to_sheet(expData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "staff.xlsx");

      this.exporting = false;
    },

    async Filter() {
      // console.log(this.filter);
      this.$bvModal.hide("modal-login");
      this.dataLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=" +
          this.filter.dep +
          "&status=" +
          this.filter.status,
        token: this.$store.state.userData.token,
      });
      this.items = myitem;
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.currentStatus = this.filter.status;
      this.dataLoading = false;
    },
    OpenImport() {
      this.visibility = true;
    },
    async ImportStaff() {
      this.staffImporting = true;
      const schema = {
        id: {
          prop: "id",
          type: Number,
        },
        rfID: {
          prop: "rfID",
          type: String,
        },
        name: {
          prop: "name",
          type: String,
        },
        father_name: {
          prop: "father_name",
          type: String,
        },
        designation: {
          prop: "designation",
          type: String,
        },
        gender: {
          prop: "gender",
          type: String,
        },
        picture: {
          prop: "picture",
          type: String,
        },
        date_of_birth: {
          prop: "date_of_birth",
          type: Date,
        },
        address_current: {
          prop: "address_current",
          type: String,
        },
        address_permanent: {
          prop: "address_permanent",
          type: String,
        },
        contact_number: {
          prop: "contact_number",
          type: String,
        },
        emergency_contact_person: {
          prop: "emergency_contact_person",
          type: String,
        },
        emergency_contact_relation: {
          prop: "emergency_contact_relation",
          type: String,
        },
        emergency_number: {
          prop: "emergency_number",
          type: String,
        },
        qualification: {
          prop: "qualification",
          type: String,
        },
        experience: {
          prop: "experience",
          type: String,
        },
        campusID: {
          prop: "campusID",
          type: Number,
        },
        canPostNewsFeed: {
          prop: "canPostNewsFeed",
          type: Boolean,
        },
        totalPeriods: {
          prop: "totalPeriods",
          type: Number,
        },
        status: {
          prop: "status",
          type: String,
        },
        deviceID: {
          prop: "deviceID",
          type: String,
        },
        departmentID: {
          prop: "departmentID",
          type: Number,
        },
        availibility: {
          prop: "availibility",
          type: String,
        },
        documents: {
          prop: "documents",
          type: String,
        },
        expectedSalary: {
          prop: "expectedSalary",
          type: Number,
        },
        appointedOn: {
          prop: "appointedOn",
          type: Date,
        },
        basicSalary: {
          prop: "basicSalary",
          type: Number,
        },
        bonuses: {
          prop: "bonuses",
          type: Number,
        },
        totalSalary: {
          prop: "totalSalary",
          type: Number,
        },
        note: {
          prop: "note",
          type: String,
        },
        allowedLeaves: {
          prop: "allowedLeaves",
          type: Number,
        },
        joinsAt: {
          prop: "joinsAt",
          type: Date,
        },
        accountTitle: {
          prop: "accountTitle",
          type: String,
        },
        accountNumber: {
          prop: "accountNumber",
          type: String,
        },
        payMode: {
          prop: "payMode",
          type: String,
        },
        joinsAt: {
          prop: "leaving_date",
          type: Date,
        },
        department: {
          prop: "department",
          type: String,
        },
        cnic: {
          prop: "cnic",
          type: String,
        },
        leaves_allowed_from: {
          prop: "leaves_allowed_from",
          type: Date,
        },
      };
      // console.log("import staff....");
      const input = document.getElementById("importStaff");
      // console.log(input.files);
      await readXlsxFile(input.files[0], { schema })
        .then((rows) => {
          // `rows` is an array of rows
          this.staffData = rows.rows;
        })
        .catch((err) => {
          console.log(err);
          this.staffImporting = false;
        });
      // console.log(this.staffData);
      const chunkSize = 10000;
      let chunkLength = 0;

      for (let i = 0; i < this.staffData.length; i += chunkSize) {
        const chunk = this.staffData.slice(i, i + chunkSize);
        chunkLength += chunk.length;

        // console.log(chunk);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Staff/SaveAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: chunk,
          message: "Staff data imported.",
          importing: true,
          lastChunk: chunkLength == this.staffData.length,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (!status) this.staffImporting = false;
        else if (chunkLength == this.staffData.length)
          this.staffImporting = false;
      }
    },
    Edit(id) {
      // console.log("myRow1111111111111111", id);
      this.$router.push({
        name: "manage-staff",
        params: {
          id: id,
        },
      });
    },
    Perf(id) {
      this.$router.push({
        name: "staff-evaluation",
        params: {
          id: id,
        },
      });
    },
    openClasses(id) {
      this.$router.push({
        name: "teaching-classes",
        params: {
          id: id,
        },
      });
    },
    timeTable(id) {
      // console.log("id", id);
      this.$router.push({
        name: "timetable",
        params: {
          id: id,
        },
      });
    },
    openPlanner(id) {
      // console.log("id", id);
      this.$router.push({
        name: "manage-planner",
        params: {
          id: id,
        },
      });
    },
    AddOpen() {
      // console.log("Helloo");
      this.$router.push({
        name: "manage-staff",
        params: {
          id: 0,
        },
      });
      //   this.$router.push(`/manage-student/${my}`);
    },
    openLeft(item) {
      this.myObj = { ...item };
      // console.log(this.myObj);
      this.visibility2 = true;
    },
    checkLeftDate() {
      var elem = this.$refs["left_date"];
      if (!this.myObj.leaving_date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markLeft() {
      if (this.checkLeftDate() == true) {
        this.saving = true;
        this.myObj.status = "left";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            this.myObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Staff marked as left successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.visibility2 = false;
          this.LoadData();
        }
      }
    },
    openReadmit(item) {
      this.myObj = { ...item };
      // console.log(this.myObj);
      this.visibility3 = true;
    },
    checkReDate() {
      var elem = this.$refs["rejoin"];
      if (!this.myObj.rejoining_date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markReadmit() {
      if (this.checkReDate() == true) {
        this.saving = true;
        this.myObj.status = "present";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            this.myObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Staff readmitted succesfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.visibility3 = false;
          this.LoadData();
        }
      }
    },
    async changeStatus(item, st) {
      let text = "";
      let message = "";
      if (st == "present") {
        text = "This will readmit the staff.";
        message = "Staff readmitted succesfully!";
      } else {
        text = "This will mark staff as left.";
        message = "Staff marked as left successfully!";
      }
      let result = await Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        item.status = st;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: item,
          message: message,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "staff/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Staff deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, Delete it!',
      // }).then(result => {
      //   if (result.isConfirmed) {

      //   }
      // });
    },
    async loadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.showSalary = res.find((el) => el.key == "show_salary");
      // console.log(this.showSalary);
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["class_teacher_attd"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.ctObj = res.find((el) => el.key == "class_teacher_attd");
      // console.log(this.ctObj);
    },

    async LoadDep() {
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      // if (this.departments.length > 0) {
      //   this.departments.unshift({ name: "All Departments", id: 0 });
      // } else this.departments = [];
    },

    async LoadData(text) {
      //  this.items =[];
      this.dataLoading = true;
      // let myitem = await this.get({
      //   url:
      //     this.$store.state.domain +
      //     "staff/filter?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId +
      //     "&dptID=0&status=" +
      //     this.currentStatus,
      //   token: this.$store.state.userData.token,
      // });
      if (text == "filter") {
        this.$bvModal.hide("modal-login");
        this.currentPage = 1;
      } else if (text == "dd") {
        this.filter.dep = 0;
        this.currentPage = 1;
      }
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/LoadDataPagination?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=" +
          this.filter.dep +
          "&status=" +
          this.currentStatus +
          "&page=" +
          this.currentPage,
        token: this.$store.state.userData.token,
        dashboard: true,
      });
      // console.log(myitem);
      this.items = myitem.data;
      this.totalCount = myitem.count;

      this.dataLoading = false;
      // console.log("items", this.items);

      let fData = {
        depID: this.filter.dep,
        page: this.currentPage,
        currentStatus: this.currentStatus,
        total: this.totalCount,
        db: this.$store.state.userData.db,
        cID: this.$store.state.userData.cId,
      };
      let ind = this.filterData.findIndex(
        (el) =>
          el.db == this.$store.state.userData.db &&
          el.cID == this.$store.state.userData.cId
      );
      if (ind != -1) {
        this.filterData[ind] = { ...fData };
      } else {
        this.filterData.push(fData);
      }
      this.$store.commit("setStaffFilter", this.filterData);

      // console.log(this.filterData);
    },
    async loadSearch() {
      if (this.searchQuery.trim() == "") {
        this.currentPage = 1;
        this.LoadData();
      } else {
        this.dataLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "staff/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&status=" +
            this.currentStatus,
          token: this.$store.state.userData.token,
        });
        this.items = myitem;
        this.totalCount = 0;
        this.currentPage = 1;
        this.dataLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
